import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'

import thunk from 'redux-thunk'

import reducer from './reducers'

import './index.css';
import './css/bootstrap.css';
import App from './App';
import {
  BrowserRouter as Router,
  //Route,
  //Link
} from 'react-router-dom'

import * as serviceWorker from './serviceWorker';

const middleware = [ thunk ]
/*if (process.env.NODE_ENV !== 'production') {
  //middleware.push(createLogger())
  middleware.push(require('redux-immutable-state-invariant').default())
}*/

  if (process.env.NODE_ENV !== 'production') {
    console.log("running in dev mode"+process.env.NODE_ENV);
  } else {
    console.log("running in production mode");
  }

const store = createStore(
  reducer,
  applyMiddleware(...middleware)
)


ReactDOM.render(
  <Provider store={store}>
  <Router>
  <App />
  </Router>
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
