import React, { Component } from "react";

import { Alert
  //, Button
} from 'react-bootstrap';
import { connect } from 'react-redux'
import { Field, reduxForm,
  //formValueSelector
} from 'redux-form'
import { withRouter } from 'react-router-dom'
import TopNav from './TopNav'
import Footer from './Footer'
const required = value => (value ? undefined : 'Required')

const renderField = ({
input,
label,
type,
meta: { touched, error, warning }
}) => (
<div>
  <input {...input} className="form-control input-lg" placeholder={label} type={type} />
  {touched &&
    ((error && <span style={{color:'red'}} >{error}</span>) ||
      (warning && <span style={{color:'red'}}>{warning}</span>))}
</div>
)
class QuestionForm extends Component {
  componentDidMount() {
    this.props.change('church_id', this.props.match.params.church_id)
    //dispatch(fetchAuthors())
    //dispatch(fetchPostById(this.props.match.params.postId, //true)) //TODO set to true!
    document.title = "Ask question"
  }

  render() {
    const {handleSubmit, isQuestioning, questionResponse} = this.props;

    return (
      <>
      <div className="container">
      <h1 style={{marginBottom:40}}>{(this.props.match.params.church_id == 1)?"Send your question or thought to St Helen's":"Ask question"}</h1>
      {(questionResponse)?<Alert bsStyle="info">{questionResponse}
          </Alert>:<form onSubmit={handleSubmit}>
         <div className="form-group">
           <div className="row">
             <div className="col-sm-6">
               <Field autoComplete="message" label="Question"  className="form-control input-lg" name="question" component="textarea" placeholder={(this.props.match.params.church_id == 1)?"Text":"Enter your question here."} rows={5} type="text" validate={[required]}/>
             </div>
           </div>
        </div>
        <Field type="hidden" name="church_id" component="input"/>
        <button type="submit" className="btn btn-primary btn-lg">Send</button>
      </form>}
    </div>
    <Footer/>
    </>
    );
  }
}

QuestionForm = reduxForm({
  form: 'question'
})(QuestionForm)

// Decorate with connect to read form values
/*const selector = formValueSelector('login') // <-- same as form name
LoginForm = connect(state => {
  // can select values individually
  //const typeValue = selector(state, 'type')
  // or together as a group
  return {
      //typeValue,
  }
})(LoginForm)*/

const mapStateToProps = state => {
  const { questionMessage } = state
  const {
    isQuestioning,
    questionResponse,
  } = questionMessage || {
    isQuestioning: true,
    questionResponse:"",
  }

  return {
      isQuestioning,
      questionResponse,
  }
}

export default withRouter(connect(mapStateToProps)(QuestionForm))
