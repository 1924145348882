import React, { Component } from "react";

import TopNav from './TopNav'
import Footer from './Footer'
import {
  Link
} from 'react-router-dom'
import {
  //Alert,
  Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faQuestion, faUserCog, faUndo, faTrashAlt, faSpinner, faFileCsv } from '@fortawesome/free-solid-svg-icons'

class Help extends Component {
  componentDidMount() {
    document.title = "Help"
  }
  render() {
    const {user} = this.props;

    return (
      <>
      <TopNav user={user}/>
      <div className="container">
        <div className="row">
        <div className="col-sm-8">
        <h2>Getting started</h2>
        {(user)?
        <>
        <p>Text questions to {user.numbertotext}. (All texts are charged at standard message rate.)</p>
        </>:null
        }

        <p>Questions can be viewed on the <Link to="/admin">admin page</Link> by one or more devices at the same time. A tablet such as an iPad in landscape mode is ideal, but a phone will also work, even in portrait mode. Interactions on any device are reflected to all other devices in realtime. Via the admin page, questions can be fetched, hidden, dragged for reordering and set to display on the projector. The questioner may prefer a simplified view and this can be found on the <Link to="/questioner">questioner page</Link></p>

        <p>The admin page displays <button className="btn btn-xs btn-primary">No new questions waiting <FontAwesomeIcon icon={faSpinner} pulse /></button> when there are no pending questions. It automatically checks for new ones in the background and becomes <button className="btn btn-xs btn-danger">Get 2 questions</button> when there are questions to fetch. Click this to populate the questions with these new ones.</p>

        <p>Unread questions are shown in <b>bold</b>. Click a question to mark it as read and continue clicking to change its colour to group it as you wish. The <Button className="btn btn-xs btn-danger"><FontAwesomeIcon icon={faTrashAlt} /></Button> to the right of each question deletes any unwanted questions. Moderate by dragging a question from the "All questions" list to the "Moderated questions" list. The <Button className="btn btn-xs btn-primary"><FontAwesomeIcon icon={faVideo} /></Button> to the left of each question makes the question appear on the projector page. If pressed by accident, this can be undone via the <Button className="btn btn-xs btn-warning"><FontAwesomeIcon icon={faUndo} /></Button> shown under "Projected question".</p>

        <p>The projector should be set to display the <Link to="/projector">projector page</Link>. The browser should be set to full screen so as not to display the toolbar. On Chrome, this can be done via View -> Enter Full Screen. When no question is displayed, information is shown for the audience with the number to send their question via SMS.</p>

        {(user && user.church_id !== 129)?<p>At the end of the question time session, please <button className="btn btn-xs btn-warning"><FontAwesomeIcon icon={faFileCsv} /> Export all</button> questions and <button className="btn btn-xs btn-danger"><FontAwesomeIcon icon={faTrashAlt} /> Delete all</button> questions ready for the next Q&A.</p>:null}

        <h2>Live demo</h2>
        {(user && user.church_id !== 129)?<p>The demo is not available to users who already have their own account.</p>:
        <><p>Try the following pages by logging in as <code>demo@askquestions.live</code> with <code>password</code> as the password.</p><Link to="/admin" className="btn btn-lg btn-warning"><FontAwesomeIcon icon={faUserCog} /> Admin</Link> <Link to="/questioner" className="btn btn-lg btn-warning"><FontAwesomeIcon icon={faQuestion} /> Questioner</Link> <Link to="/projector" className="btn btn-lg btn-warning"><FontAwesomeIcon icon={faVideo} /> Projector</Link></>}

        <h2>Problems</h2>
        {(user && (user.church_id === 1 || user.church_id === 358 || user.church_id === 314 || user.church_id === 390 || user.church_id === 281 || user.church_id === 441 || user.church_id === 480 || user.church_id === 488 ))?
        <>
        <div className="alert alert-danger" role="alert">If problems persist, sign in to the old version of the app <a href="https://sharedbookshelves.com/questiontime2">here</a>.</div>
        </>:null
        }
        <p>If no questions appear for a suspiciously long time, refresh the page and log back in.</p>
        <p>Please <Link to="/contact">contact us</Link> if you experience any problems.</p>
        </div>
        <div className="col-sm-4" style={{textAlign:'center',margin:'auto'}}>
        <img src="/img/phone3.png" alt="" style={{maxWidth:'300px'}}/>
        </div>
        </div>
      </div>
      <Footer/>
      </>
    );
  }
}

export default Help
