import React, { Component } from "react";
import { Alert } from 'react-bootstrap';
import { socket } from "../global/header";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import moment from 'moment'
import TopNav from './TopNav'
import { CSVLink } from "react-csv";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faVideo, faTrashAlt, faUndo, faFileCsv, faSpinner, faArchive } from '@fortawesome/free-solid-svg-icons'

const Poller = require('./poller');

class Console extends Component {
  constructor(props) {
    super(props);

    this.onDragEnd = this.onDragEnd.bind(this);
    this.state = {
      question_bank: [],
      max_id:this.props.user.max_id,
      // this is where we are connecting to with sockets,
      show1: true,
      show2: true,
    };

    this.handleDismiss1 = this.handleDismiss1.bind(this);
    this.handleDismiss2 = this.handleDismiss2.bind(this);

    if(this.props.admin){
      let poller = new Poller(4000);

      // Wait till the timeout sent our event to the EventEmitter
      poller.onPoll(() => {
        //console.log('triggered');
        socket.emit("initial_data", {church_id:this.props.user.church_id, max_id:this.state.max_id})
        poller.poll();
      })
      // Initial start
      poller.poll();
    }
  }

  handleDismiss1() {
    this.setState({ show1: false });
  }

  handleDismiss2() {
    this.setState({ show2: false });
  }

  getData = questions => {
    //console.log(questions);
    var tempmax = Math.max.apply(Math, questions.filter((o) => o.boxref > 0).map((o) => o.id))
    if (tempmax > this.state.max_id) {
      console.log("reset max");
      this.setState({
        question_bank: questions,
        max_id: tempmax });
    } else {
      //console.log("no resetting max");
      this.setState({ question_bank: questions });
    }
  };

  getMaxID = id => {
    console.log("max id reset to "+id);
    this.setState({max_id:id})
  }

  changeData = () => socket.emit("initial_data", {
    church_id:this.props.user.church_id,
    max_id:this.state.max_id
  });

  componentDidMount() {
    socket.emit("initial_data", {
      church_id:this.props.user.church_id,
      max_id:this.props.user.max_id
    });
    socket.on("get_data", this.getData);
    socket.on("get_max_id", this.getMaxID);
    socket.on("change_data", this.changeData);
    if (this.props.admin) {
      document.title = "Admin"
    } else {
      document.title = "Questioner"
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.admin) {
      document.title = "Admin"
    } else {
      document.title = "Questioner"
    }
  }

  componentWillUnmount() {
    socket.off("get_data");
    socket.off("get_max_id");
    socket.off("change_data");
  }

  onDragEnd(result) {
    var array = [...this.state.question_bank];
    const index = array.map(e => e.id).indexOf(result.draggableId);
    //console.log(JSON.stringify(result, null, 3));
    if (result.destination == null) {
      //no destination
    } else if (result.source.droppableId !== result.destination.droppableId) {
      if (result.destination.droppableId === "questions") {
        array[index].boxref = 0;
      } else if (result.destination.droppableId === "stack") {
        array.forEach(function(item) {
          if ((item.boxref === 1) && (item.stackorder >= result.destination.index)) {
            item.stackorder=item.stackorder+1
          }
        })
        array[index].boxref = 1;
        array[index].stackorder = result.destination.index
      } else {
        alert("unknown")
      }

      this.setState({question_bank: array});
      console.log("move")
      socket.emit("move_question", {
        church_id:this.props.user.church_id,
        result:result
      })
    } else {
      if (result.source.droppableId === "questions") {
        //alert("Only questions in the stack can be re-ordered")
      } else if (result.source.droppableId === "stack") {
        console.log("reorder")
        if (result.destination.index > result.source.index) {
          array.forEach(function(item) {
            if ((item.stackorder > result.source.index) && (item.stackorder <= result.destination.index)) {
              item.stackorder = item.stackorder - 1
            }
          })
        } else {
          array.forEach(function(item) {
            if ((item.stackorder >= result.destination.index) && (item.stackorder < result.source.index)) {
              item.stackorder = item.stackorder + 1
            }
          })
        }
        array[index].stackorder = result.destination.index
        this.setState({question_bank: array});
        socket.emit("reorder_question", {
          church_id:this.props.user.church_id, result:result
        })
      } else {
        //should not get here
      }
    }
  }

  projectQuestion = (id, boxref, stackorder) => {
    /*var array = [...this.state.question_bank];
    const index = array.map(e => e.id).indexOf(id);
    array[index].projected = 1
    array[index].boxref = 2
    this.setState({question_bank: array});*/
    socket.emit("project_question", {
      id:id,
      boxref:boxref,
      stackorder:stackorder, church_id:this.props.user.church_id
    });
  };

  unprojectQuestion = (id) => {
    socket.emit("unproject_question", {
      id:id,
      church_id:this.props.user.church_id
    });
  };

  hideQuestion = (id, boxref, stackorder) => {
    socket.emit("hide_question", {
      id:id,
      boxref:boxref,
      stackorder:stackorder, church_id:this.props.user.church_id});
  };

  newQuestionsButton() {
    //console.log("num new questions");
    var array = this.state.question_bank.filter(question => (question.hidden === 0) && (question.id > this.state.max_id))
    return (
      <button disabled={(array.length === 0)?true:false} style={{margin:'5px'}} className={(array.length > 0) ? 'btn btn-lg btn-danger':'btn btn-lg btn-primary'} onClick={() => this.getNewQuestions()}>{(array.length === 0)?<>No new questions waiting <FontAwesomeIcon icon={faSpinner} pulse /></>:(array.length === 1)?<>Get 1 new question</>:<>Get {array.length} new questions</>}</button>
    )
  }

  getNewQuestions = () => {
    socket.emit("get_new_questions", {church_id:this.props.user.church_id})
  }

  deleteQuestions = () => {
    if(window.confirm("Are you sure you wish to delete all the questions?")) {
      this.setState({ question_bank: [] });
      socket.emit("delete_questions", {church_id:this.props.user.church_id})
    }
  }

  readQuestion = (id) => {
    socket.emit("read_question", {
      id:id,
      church_id:this.props.user.church_id})
  }

  getProjectedQuestion() {
    var array = this.state.question_bank.filter(question => question.projected === 1 && question.hidden === 0);
    if (array.length > 0) {
      return (
        <div style={{'textAlign':'center',padding:'5px',margin:'5px',backgroundColor:(array[0].readstatus) ?'#ffffff':'#eeeeee'}} onClick={() => this.readQuestion(array[0].id)}><button onClick={(e) => {e.stopPropagation();this.unprojectQuestion(array[0].id)}} style={{ marginRight:'2px', float:'left'}} className="btn btn-warning"><FontAwesomeIcon icon={faUndo} /></button><span style={{fontWeight:(array[0].readstatus) ?'normal':'bold'}}>{moment(array[0].time*1000).format('ddd, MMM Do YYYY @ HH:mm:ss')} {(this.props.user.church_id === 314) ? '0'+array[0].msisdn.substring(2) : ''}</span><button onClick={(e) => {e.stopPropagation(); this.hideQuestion(array[0].id, array[0].boxref, array[0].stackorder)}} className="btn btn-danger" style={{float:'right', margin:'2px'}}><FontAwesomeIcon icon={faTrashAlt} /></button><div style={{clear:'both'}}></div> <span style={{display:'block','textAlign':'justify'}}>{array[0].text}</span></div>
      )
    } else {
      return (
        <div>(No question yet.) Text your question to {this.props.user.numbertotext} or via https://askquestions.live/ask/{this.props.user.church_id}</div>
      )
    }
  }

  getNumQuestions(boxref) {
    var array = this.state.question_bank.filter(question => (question.hidden === 0) && (question.id <= this.state.max_id) && (question.boxref === boxref))
    return array.length;
  }

  getQuestionData(boxref) {
    //console.log("get q data"+boxref)
    var array = this.state.question_bank.filter(question => (question.hidden === 0) && (question.id <= this.state.max_id) && (question.boxref === boxref))
    if (boxref === 1) {
      function compare( a, b ) {
        if ( a.stackorder < b.stackorder ){
          return -1;
        }
        if ( a.stackorder > b.stackorder ){
          return 1;
        }
        return 0;
      }
      array.sort(compare)
    }
    return array.map((question,i) => {
      return (
        <Draggable draggableId={question.id} index={i} key={question.id}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <div className={(question.readstatus > 0)?(question.readstatus === 1)?"btn-default":(question.readstatus === 2)?"btn-primary":(question.readstatus === 3)?"btn-success":(question.readstatus === 4)?"btn-info":(question.readstatus === 5)?"btn-warning":(question.readstatus === 6)?"btn-danger":null:null} style={{'textAlign':'center',padding:'5px',margin:'5px',backgroundColor:(question.readstatus===0) ?'#eeeeee':null}} onClick={() => this.readQuestion(question.id)}>
                <button onClick={(e) => {e.stopPropagation();this.projectQuestion(question.id, question.boxref, question.stackorder)}} style={{ marginRight:'2px', float:'left'}} className="btn btn-primary"><FontAwesomeIcon icon={faVideo} /></button><span style={{fontWeight:(question.readstatus) ?'normal':'bold'}}>{moment(question.time*1000).format('ddd, MMM Do YYYY @ HH:mm:ss')} {(this.props.user.church_id === 314) ? '0'+question.msisdn.substring(2) : ''}</span><button onClick={(e) => {e.stopPropagation();this.hideQuestion(question.id, question.boxref, question.stackorder)}} style={{float:'right', marginLeft:'2px'}} className="btn btn-danger"><FontAwesomeIcon icon={faTrashAlt} /></button><div style={{clear:'both'}}></div> <span style={{display:'block','textAlign':'justify',fontWeight:(question.readstatus) ?'normal':'bold'}}>{question.text}</span>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  }

  csvData() {
    var array = this.state.question_bank.map(q => [q.text])
    //console.log(array)
    return array;
  }

  render() {
    const {user} = this.props;
    return (
    <DragDropContext onDragEnd={this.onDragEnd} >
      <TopNav user={user}/>
      <div className="container" style={{height:'100%'}}>
      {(this.props.user.church_id === 129)?<>{(this.state.show1)?<Alert bsStyle="warning" onDismiss={this.handleDismiss1}><b>This is a demo.</b> Try texting a question to (+44) {this.props.user.numbertotext} and using collaboratively on multiple devices by dragging and dropping questions.</Alert>:null}{(this.state.show2)?<Alert bsStyle="info" onDismiss={this.handleDismiss2}>AskQuestions is currently in beta and its use is by invitation only. &nbsp;
            <Link to="/contact" className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEnvelope} /> Request an invitation</Link>
          </Alert>:null}</>:null}
        <div className="row">
        {(this.props.admin)?
          <div className="col-sm-6">
          {this.newQuestionsButton()}
          </div>:null}
          <div className={(this.props.admin)?"col-sm-6":"col-sm-12"}>
            <h4>Projected question</h4>
            {this.getProjectedQuestion()}
          </div>
        </div>
        <div className="row" style={{height:'70%'}}>
        {(this.props.admin)?
          <div className="col-sm-6" style={{height:'100%'}}>
            <h4>All questions <span className="badge">{this.getNumQuestions(0)}</span></h4>
            <Droppable droppableId="questions">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={{
                  height:'90%',
                  'overflowY':'scroll',backgroundColor: snapshot.isDraggingOver ? '#999999' : '#cccccc' }}
                {...provided.droppableProps}
              >
                {provided.placeholder}
                {this.getQuestionData(0)}
              </div>
            )}
            </Droppable>
          </div>:null}
          <div className={(this.props.admin)?"col-sm-6":"col-sm-12"} style={{height:'100%'}}>
          <h4>Moderated questions{(this.props.user.church_id === 1)?" (5 maximum)":null} <span className="badge">{this.getNumQuestions(1)}</span></h4>
            <Droppable droppableId="stack">
            {(provided, snapshot) => (
            <div
            ref={provided.innerRef}
            style={{
              height:'90%',
              'overflowY':'scroll', backgroundColor: snapshot.isDraggingOver ? '#999999' : '#cccccc' }}
            {...provided.droppableProps}
            >
            {provided.placeholder}
            {this.getQuestionData(1)}
            </div>
            )}
            </Droppable>
          </div>

        <div className="col-sm-6">
        {(this.props.admin)?(this.props.user.church_id === 129)?null:
        <><br />
        <CSVLink data={this.csvData()}
  filename={"questions"+moment().format('YYYY-MM-DD')+".csv"} className="btn btn-warning"><FontAwesomeIcon icon={faFileCsv} /> Export all</CSVLink>&nbsp;
        <button onClick={() => this.deleteQuestions()} className="btn btn-danger"><FontAwesomeIcon icon={faTrashAlt} /> Delete all</button></>:null}
          &nbsp;
        <Link to="/archive">
          <button className="btn btn-primary"><FontAwesomeIcon icon={faArchive} /> View archive</button>
        </Link>

      </div></div></div>
    </DragDropContext>
    );
  }
}

export default Console
