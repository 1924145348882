import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Header } from "./global/header";
import { Switch, Route,
  withRouter,
  Redirect
} from "react-router-dom";
import Console from "./main/Console";
import Projector from "./main/Projector";
import LoginForm from "./main/LoginForm";
import Archive from "./main/Archive";
import Home from "./main/Home";
import Help from "./main/Help";
import ContactForm from "./main/ContactForm";
import QuestionForm from "./main/QuestionForm";
import NotFound from "./main/NotFound";
import { logIn, logOut, contact, question } from './actions'

class App extends Component {
  login = values => {
    // print the form values to the console
    console.log('login:'+JSON.stringify(values))
    this.props.dispatch(logIn(values))
  }

  contact = values => {
    console.log('contact'+JSON.stringify(values))
    this.props.dispatch(contact(values))
  }

  question = values => {
    console.log('question'+JSON.stringify(values))
    this.props.dispatch(question(values))
  }

//TODO timeout
  render() {

    const { loggedInUser, isLoggingIn, loginError } = this.props

    return (
<div className="App">
{
           (loggedInUser) ?
      <div className="App2">
        <Header/>
        <Switch>
          <Route exact path="/" render={() => <Home  user={loggedInUser}/>}/>
          <Route exact path="/admin" render={() => <Console user={loggedInUser} admin={true}/>}/>
          <Route exact path="/questioner" render={() => <Console user={loggedInUser} admin={false}/>}/>
          <Route exact path="/projector" render={() => <Projector user={loggedInUser}/>}/>
          <Route exact path="/archive" render={() => <Archive user={loggedInUser}/>}/>
          <Route exact path="/help" render={() => <Help user={loggedInUser}/>}/>
          <Route exact path="/contact" render={() => <ContactForm user={loggedInUser} onSubmit={this.contact}/>}/>
          <Route exact path="/ask/:church_id" render={() => <QuestionForm user={loggedInUser} onSubmit={this.question}/>}/>
          <Route exact path='/not-found' component={NotFound} />
          <Route exact path='/signin' render={() => <Redirect to='/' />} />
          <Route render={() => <Redirect to='/not-found' />} />
        </Switch>
      </div> : (isLoggingIn) ? <div className="container"><span>Logging in...</span></div> :
          <Switch>
            <Route exact path="/" render={() => <Home />}/>
            <Route exact path="/admin" render={() => <LoginForm
              loginError={loginError}
              onSubmit={this.login}/>}/>
            <Route exact path="/questioner" render={() => <LoginForm
              loginError={loginError}
              onSubmit={this.login}/>}/>
            <Route exact path="/archive" render={() => <LoginForm
              loginError={loginError}
              onSubmit={this.login}/>}/>
            <Route exact path="/projector" render={() =>    <LoginForm
                loginError={loginError}
                onSubmit={this.login}/>}/>
            <Route exact path="/help" render={() => <Help/>}/>
            <Route exact path="/contact" render={() => <ContactForm onSubmit={this.contact}/>}/>
            <Route exact path="/ask/:church_id" render={() => <QuestionForm onSubmit={this.question}/>}/>
            <Route exact path="/signin" render={() => <LoginForm
              loginError={loginError}
              onSubmit={this.login}/>}/>
            <Route component={NotFound} />
          </Switch>
  }
  </div>
    );
  }
}

const mapStateToProps = state => {
  const { logInMessage } = state
  const {
    isLoggingIn,
    loggedInUser,
    loginError,
  } = logInMessage || {
    isLoggingIn: true,
    loggedInUser:false,
    loginError:"",
  }

  return {
      loggedInUser,
      isLoggingIn,
      loginError,
  }
}
export default withRouter(connect(mapStateToProps)(App))
