import React, { Component } from "react";
//import { NavLink } from "react-router-dom";
import socketIOClient from "socket.io-client";
//import "./header.css";
// The Header creates links that can be used to navigate
// between routes.
var socket;
class Header extends Component {
/* Creating a Socket client and exporting it at the end to be used across the Place Order, Kitchen, etc components*/
  constructor() {
    super();
    this.state = {
      endpoint: '/'
    };
socket = socketIOClient(this.state.endpoint);
  }
render() {
    return (
      <header>
      </header>
    );
  }
}
export { Header, socket };
