import React, { Component } from "react";
import TopNav from './TopNav'

class NotFound extends Component {
  render() {
    return (
      <>
      <TopNav/>
      <div className="container">
        <h1>404 Not Found</h1>
      </div>
      </>
    );
  }
}

export default NotFound
