import React, { Component } from "react";

import { Navbar, Nav,
  NavItem,
  //NavDropdown, MenuItem,
  Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faQuestion, faUserCog, faInfoCircle, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import {
  Link
} from 'react-router-dom'

class TopNav extends Component {
  render() {
    return (
      <Navbar inverse collapseOnSelect staticTop>
        <Navbar.Header>
          <Link to="/">
            <Image src="/img/textaquestionlogo.png" className="navbar-brand" alt="logo"/> <span className="navbar-brand">AskQuestions.live</span>
          </Link>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <LinkContainer to="/help">
              <NavItem eventKey={1}><FontAwesomeIcon icon={faInfoCircle} /> Help</NavItem>
            </LinkContainer>
            <LinkContainer to="/admin">
              <NavItem eventKey={2}><FontAwesomeIcon icon={faUserCog} /> Admin</NavItem>
            </LinkContainer>
            <LinkContainer to="/questioner">
              <NavItem eventKey={3}><FontAwesomeIcon icon={faQuestion} /> Questioner</NavItem>
            </LinkContainer>
            <LinkContainer to="/projector">
              <NavItem eventKey={4}><FontAwesomeIcon icon={faVideo} /> Projector</NavItem>
            </LinkContainer>
            <LinkContainer to="/contact">
              <NavItem eventKey={5}><FontAwesomeIcon icon={faEnvelope} /> Contact</NavItem>
            </LinkContainer>
          </Nav>
          <Nav pullRight>
          {(this.props.user)?
                <NavItem eventKey={7}>{this.props.user.email}</NavItem>:null
          }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default TopNav
