import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="container" style={{textAlign:'center'}}>
      <hr />
      <p>&copy; <a href="/">AskQuestions.live</a></p>
      </div>
    );
  }
}

export default Footer
