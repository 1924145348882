import React, { Component } from "react";
//import useFitText from "use-fit-text";
//import { Button, Table, Container } from "reactstrap";
import { socket } from "../global/header";
//import ReactHTMLTableToExcel from "react-html-table-to-excel";

import Example from "./Example";
import '../projector.css';

class Projector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question_bank: []
      // this is where we are connecting to with sockets,
    };
  }

  getData = questions => {
    //console.log(questions);
    this.setState({ question_bank: questions });
  };

  changeData = () => socket.emit("initial_data",{church_id:this.props.user.church_id});

  componentDidMount() {
    //var state_current = this;
    socket.emit("initial_data",{church_id:this.props.user.church_id});
    socket.on("get_data", this.getData);
    socket.on("change_data", this.changeData);

    document.title = "Projector"
  }

  componentWillUnmount() {
    socket.off("get_data");
    socket.off("change_data");
  }

  getQuestionToProject() {
    var array = this.state.question_bank.filter(question => question.projected === 1 && question.hidden === 0);

    if (array.length > 0) {
      return (
        <>
        <div style={{
          display:'flex',
          alignItems: 'center',
          flexDirection: 'column',
        fontSize:'50px',
        color:'#ffffff',
        textAlign:'center',
        justifyContent: 'center',
        backgroundColor:'#000000',
        height:'100%',
        }}>
        <Example q={(this.props.user.church_id === 129)?"--Demo account-- "+array[0].text:array[0].text}/>
        <span style={{display:'block', fontSize:'20px', marginTop:'10%'}}>Text your question to {this.props.user.numbertotext} or via https://askquestions.live/ask/{this.props.user.church_id}</span>
        </div>
          {/*<div style={{
          display: 'flex',
          color:'#ffffff',
          textAlign:'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor:'#000000',
          height:'100%',
          width:'100%'}}>
          <span style={{marginLeft:'20%',marginRight:'20%',}}>{(this.props.user.church_id === 129)?"--Demo account-- ":null}<Example a={array[0].text}/></span><span style={{fontSize:'20px', marginTop:'10%'}}>Text your question to {this.props.user.numbertotext} or via https://askquestions.live/ask/{this.props.user.church_id}</span></div>*/}
          </>
      )
    } else {
      return (
        <div style={{
          display: 'flex',
          color:'#ffffff',
          fontSize:'50px',
          textAlign:'center',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor:'#000000',
          height:'100%',
          width:'100%'}}><span style={{margin:'5%'}}>Text your question to<br /> {this.props.user.numbertotext} or via https://askquestions.live/ask/{this.props.user.church_id}</span></div>
      )
    }
  }

  render() {
    return (
      <>
      {this.getQuestionToProject()}
      </>
    );
  }
}
export default Projector;
