
export const REQUEST_LOG_OUT = 'REQUEST_LOG_OUT'
export const REQUEST_LOG_IN = 'REQUEST_LOG_IN'
export const RECEIVE_LOG_IN = 'RECEIVE_LOG_IN'
export const REQUEST_ARCHIVE = 'REQUEST_ARCHIVE'
export const RECEIVE_ARCHIVE = 'RECEIVE_ARCHIVE'
export const REQUEST_CONTACT = 'REQUEST_CONTACT'
export const RECEIVE_CONTACT = 'RECEIVE_CONTACT'
export const REQUEST_QUESTION = 'REQUEST_QUESTION'
export const RECEIVE_QUESTION = 'RECEIVE_QUESTION'

export const logOut = () => dispatch => {
  console.log('logging out');
  dispatch(requestLogOut())
}

//TODO set any new talkid, speakerid, seriesid, uploadprogress to zero
export const requestLogOut = () => ({
  type: REQUEST_LOG_OUT,
})

export const requestLogIn = (values) => ({
  type: REQUEST_LOG_IN,
  values
})

export const receiveLogIn = (values, json) => ({
  type: RECEIVE_LOG_IN,
  values,
  logInMessage: json,
})

export const logIn = (values) => dispatch => {
  console.log('logging in');

  dispatch(requestLogIn(values))
  //console.log(values)
  return fetch('/signin', {
      method: "POST",
      mode:'cors',
      //credentials: 'include',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        //.'Cache': 'no-cache'
      },
      body: JSON.stringify(values)
    })
    .then(response => {
      /*console.log('headers:'+JSON.stringify(response)); // undefined
          console.log(document.cookie); // nope
          console.log("text:"+response.text());
        if (JSON.parse(response.text())) {
          console.log("can parse response");
        } else {
          console.log("cannot parse response");
        }*/
        console.log("resp"+JSON.stringify(response))
      return response.text()
    })
    .then(function(text) {
      //console.log("text:"+text);
      try {
        var json = JSON.parse(text);
        //console.log('login response:' + JSON.stringify(json))
        return dispatch(receiveLogIn(values, json))
      } catch (e) {
        console.log(e);
        return dispatch(receiveLogIn(values, {
          error: ["Could not sign in."]
        }))
      }
    })
}

export const requestArchive = (values) => ({
  type: REQUEST_ARCHIVE,
  values
})

export const receiveArchive = (values, json) => ({
  type: RECEIVE_ARCHIVE,
  values,
  archive: json,
})

export const fetchArchive = (church_id) => dispatch => {
  console.log('getting archive');

  dispatch(requestArchive(church_id))
  console.log(church_id)
  return fetch('/getarchive/'+church_id+'/1', {
      method: "GET",
      mode:'cors',
      //credentials: 'include',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        //.'Cache': 'no-cache'
      },
      //body: JSON.stringify(church_id)
    })
    .then(response => {
      /*console.log('headers:'+JSON.stringify(response)); // undefined
          console.log(document.cookie); // nope
          console.log("text:"+response.text());
        if (JSON.parse(response.text())) {
          console.log("can parse response");
        } else {
          console.log("cannot parse response");
        }*/
        //console.log("resp"+JSON.stringify(response))
      return response.text()
    })
    .then(function(text) {
      console.log("text:"+text);
      try {
        var json = JSON.parse(text);
        //console.log('login response:' + JSON.stringify(json))
        return dispatch(receiveArchive(church_id, json))
      } catch (e) {
        console.log(e);
        return dispatch(receiveArchive(church_id, {
          error: ["Could not getArchive."]
        }))
      }
    })
}

export const requestContact = (values) => ({
  type: REQUEST_CONTACT,
  values
})

export const receiveContact = (values, json) => ({
  type: RECEIVE_CONTACT,
  values,
  contactMessage: json,
})

export const contact = (values) => dispatch => {
  console.log('contacting');

  dispatch(requestContact(values))
  //console.log(values)
  return fetch('/message', {
      method: "POST",
      mode:'cors',
      //credentials: 'include',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        //.'Cache': 'no-cache'
      },
      body: JSON.stringify(values)
    })
    .then(response => {
      /*console.log('headers:'+JSON.stringify(response)); // undefined
          console.log(document.cookie); // nope
          console.log("text:"+response.text());
        if (JSON.parse(response.text())) {
          console.log("can parse response");
        } else {
          console.log("cannot parse response");
        }*/
      //console.log("resp"+JSON.stringify(response))
      return response.text()
    })
    .then(function(text) {
      console.log("text:"+text);
      try {
        var json = JSON.parse(text);
        console.log('contact response:' + JSON.stringify(json))
        return dispatch(receiveContact(values, json))
      } catch (e) {
        console.log(e);
        return dispatch(receiveContact(values, {
          error: ["Could not send message."]
        }))
      }
    })
}

export const requestQuestion = (values) => ({
  type: REQUEST_QUESTION,
  values
})

export const receiveQuestion = (values, json) => ({
  type: RECEIVE_QUESTION,
  values,
  questionMessage: json,
})

export const question = (values) => dispatch => {
  console.log('questioning');

  dispatch(requestQuestion(values))
  //console.log(values)
  return fetch('/sendquestion', {
      method: "POST",
      mode:'cors',
      //credentials: 'include',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        //.'Cache': 'no-cache'
      },
      body: JSON.stringify(values)
    })
    .then(response => {
      /*console.log('headers:'+JSON.stringify(response)); // undefined
          console.log(document.cookie); // nope
          console.log("text:"+response.text());
        if (JSON.parse(response.text())) {
          console.log("can parse response");
        } else {
          console.log("cannot parse response");
        }*/
      //console.log("resp"+JSON.stringify(response))
      return response.text()
    })
    .then(function(text) {
      console.log("text:"+text);
      try {
        var json = JSON.parse(text);
        console.log('question response:' + JSON.stringify(json))
        return dispatch(receiveQuestion(values, json))
      } catch (e) {
        console.log(e);
        return dispatch(receiveQuestion(values, {
          error: ["Could not send question."]
        }))
      }
    })
}
