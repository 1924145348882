import React, { Component } from "react";

import { Alert
  //, Button
} from 'react-bootstrap';
import { connect } from 'react-redux'
import { Field, reduxForm,
  //formValueSelector
} from 'redux-form'
import { withRouter } from 'react-router-dom'
import TopNav from './TopNav'
import Footer from './Footer'
const required = value => (value ? undefined : 'Required')

const renderField = ({
input,
label,
type,
meta: { touched, error, warning }
}) => (
<div>
  <input {...input} className="form-control input-lg" placeholder={label} type={type} />
  {touched &&
    ((error && <span style={{color:'red'}} >{error}</span>) ||
      (warning && <span style={{color:'red'}}>{warning}</span>))}
</div>
)
class ContactForm extends Component {
  /*state = {
    error: null,
    submitted: false,
    fields: { name: '', email: '', message: ''}
  };*/

  /*submitForm(event) {
    console.log("submit");
    console.log(JSON.stringify(event.target));
    alert("hi")
    return;
    const formElement = event.target;
    const {name, email, message} = formElement.elements;
    // build the request payload which includes the url of the end-point we want to hit
    const payload = {
      url: 'contact',
      name: name,
      email: email,
      message: message,
    };

    // call the post helper function which returns a promise, which we can use to update the
    // state of our component once returned
    post(payload)
      .then(() => {
        // on success, clear any errors and set submitted state to true
        console.log("succcess");
        this.setState({error: null, submitted: true});
      })
      .catch(error => {
        // on error, update error state with the error message and set submitted to false
        alert(error.message)
        console.log("some error");
        this.setState({error: error.message, submitted: false});
      });
        //alert("submitted");
  }*/

  componentDidMount() {
    document.title = "Contact"
  }

  render() {
    const {handleSubmit, isContacting, contactResponse} = this.props;

    return (
      <>
      <TopNav/>
      <div className="container">
      <h1>Contact us</h1>
      {(contactResponse)?<Alert bsStyle="info">{contactResponse}
          </Alert>:<form onSubmit={handleSubmit}>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-6">
                <Field autoComplete="name" label="Name" className="form-control" name="name" component={renderField} type="text" validate={[required]}/>
          </div>
        </div>
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-sm-6">
             <Field autoComplete="email" label="Email" className="form-control" name="email" component={renderField} type="text" validate={[required]}/>
             </div>
           </div>
         </div>
         <div className="form-group">
           <div className="row">
             <div className="col-sm-6">
               <Field autoComplete="message" label="Message"  className="form-control input-lg" name="message" component="textarea" placeholder="Message" rows={5} type="text" validate={[required]}/>
             </div>
           </div>
        </div>
        <button type="submit" className="btn btn-primary btn-lg">Send</button>
      </form>}
    </div>
    <Footer/>
    </>
    );
  }
}

ContactForm = reduxForm({
  form: 'contact'
})(ContactForm)

// Decorate with connect to read form values
/*const selector = formValueSelector('login') // <-- same as form name
LoginForm = connect(state => {
  // can select values individually
  //const typeValue = selector(state, 'type')
  // or together as a group
  return {
      //typeValue,
  }
})(LoginForm)*/

const mapStateToProps = state => {
  const { contactMessage } = state
  const {
    isContacting,
    contactResponse,
  } = contactMessage || {
    isContacting: true,
    contactResponse:"",
  }

  return {
      isContacting,
      contactResponse,
  }
}

export default withRouter(connect(mapStateToProps)(ContactForm))
