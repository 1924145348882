import {
  createStore,
  combineReducers
} from 'redux'
import {
  reducer as formReducer
} from 'redux-form'
import {
  REQUEST_LOG_OUT,
  REQUEST_LOG_IN,
  RECEIVE_LOG_IN,
  REQUEST_ARCHIVE,
  RECEIVE_ARCHIVE,
  REQUEST_CONTACT,
  RECEIVE_CONTACT,
  REQUEST_QUESTION,
  RECEIVE_QUESTION,
} from '../actions'

const logInMessage = (state = {
  isLoggingIn: false,
  loggedInUser: 0,
  loginError: "",
  items: []
}, action) => {
  switch (action.type) {
    case REQUEST_LOG_OUT:
      return Object.assign({}, state, {
        //...state,
        isLoggingIn: false,
        loggedInUser: 0,
        loginError: "You have been logged out due to inactivity"
      })
    case REQUEST_LOG_IN:
      return Object.assign({}, state, {
        //...state,
        isLoggingIn: true,
        loggedInUser: 0,
        loginError: "",
      })
    case RECEIVE_LOG_IN:
      console.log('logging in:' + JSON.stringify(action));
      return Object.assign({}, state, {
        //...state,
        isLoggingIn: false,
        loginError: (action.logInMessage.error) ? action.logInMessage.error : "",
        loggedInUser: (action.logInMessage.error) ? null : action.logInMessage,
        items: action,
      })
    default:
      return state
  }
}

const archive = (state = {
  items: []
}, action) => {
  switch (action.type) {
    case REQUEST_ARCHIVE:
      return Object.assign({}, state, {
      })
    case RECEIVE_ARCHIVE:
      console.log('gettin arch in:' + JSON.stringify(action));
      return Object.assign({}, state, {
        //...state,
        items: action.archive.archive,
      })
    default:
      return state
  }
}

const contactMessage = (state = {
  isContacting: false,
  contactResponse: "",
  items: []
}, action) => {
  switch (action.type) {
    case REQUEST_CONTACT:
      return Object.assign({}, state, {
        //...state,
        isContacting: true,
        contactResponse: "",
      })
    case RECEIVE_CONTACT:
      return Object.assign({}, state, {
        //...state,
        isContacting: false,
        contactResponse: (action.contactMessage.success) ? "Your message has been sent. We aim to response within 24hrs." : "Your message could not be sent. Please try again.",
        items: action,
      })
    default:
      return state
  }
}

const questionMessage = (state = {
  isQuestioning: false,
  questionResponse: "",
  items: []
}, action) => {
  //console.log('logging in'+JSON.stringify(action));
  //var tempTime = moment.duration(IDLE_TIME_OUT);
  switch (action.type) {
    case REQUEST_QUESTION:
      return Object.assign({}, state, {
        //...state,
        isQuestioning: true,
        questionResponse: "",
      })
    case RECEIVE_QUESTION:
      return Object.assign({}, state, {
        //...state,
        isQuestioning: false,
        questionResponse: (action.questionMessage.success) ? "Your question has been sent." : "Your question could not be sent. Please try again.",
        items: action,
      })
    default:
      return state
  }
}

const rootReducer = combineReducers({
  form: formReducer,
  logInMessage,
  contactMessage,
  questionMessage,
  archive,
})

const store = createStore(rootReducer)

export default rootReducer
