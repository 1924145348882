import React, { Component } from "react";
import moment from 'moment'
import { Alert
  //, Button
} from 'react-bootstrap';
import { connect } from 'react-redux'
import { Field, reduxForm,
  //formValueSelector
} from 'redux-form'
import { withRouter } from 'react-router-dom'
import TopNav from './TopNav'
import Footer from './Footer'
import {
  fetchArchive,
}
from '../actions'

class Archive extends Component {
  componentDidMount() {
    const {
      dispatch
    } = this.props
    //this.props.change('church_id', this.props.match.params.church_id)
    //dispatch(fetchAuthors())
    //dispatch(fetchPostById(this.props.match.params.postId, //true)) //TODO set to true!
    document.title = "Archive"
    dispatch(fetchArchive(this.props.user.church_id))
  }

  render() {
  //  const {handleSubmit, isQuestioning, questionResponse} = this.props;

      const {user, archive} = this.props;
        console.log(archive)
    return (
      <>
        <TopNav user={user}/>
      <div className="container">
      <h1 style={{marginBottom:40}}>Archive</h1>
      {
        (archive && archive.items.length > 0)?archive.items.map((item) => <div>{moment(item.time*1000).format('D MMM YYYY HH:mm')} {item.text}</div>):null
      }
    </div>
    <Footer/>
    </>
    );
  }
}

/*Archive = reduxForm({
  form: 'question'
})(Archive)*/

// Decorate with connect to read form values
/*const selector = formValueSelector('login') // <-- same as form name
LoginForm = connect(state => {
  // can select values individually
  //const typeValue = selector(state, 'type')
  // or together as a group
  return {
      //typeValue,
  }
})(LoginForm)*/

const mapStateToProps = state => {
  const { archive } = state
  /*const {
    isQuestioning,
    questionResponse,
  } = questionMessage || {
    isQuestioning: true,
    questionResponse:"",
  }*/

  return {
      archive
  }
}

export default withRouter(connect(mapStateToProps)(Archive))
