import React, {Component} from 'react'
//import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm,
  //formValueSelector
} from 'redux-form'
//import { logIn } from '../actions'
//import Navigation from './Navigation'
import { withRouter } from 'react-router-dom'
import TopNav from './TopNav'
import Footer from './Footer'

const required = value => (value ? undefined : 'Required')
/*const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'Only alphanumeric characters'
      : undefined*/
const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
  }) => (
      <div>
        <input {...input} className="form-control input-lg" placeholder={label} type={type} />
        {touched &&
          ((error && <span style={{color:'red'}} >{error}</span>) ||
            (warning && <span style={{color:'red'}}>{warning}</span>))}
      </div>
  )

class LoginForm extends Component {

  /*static propTypes = {
    //isLoggingIn: PropTypes.bool.isRequired,
      //dispatch: PropTypes.func.isRequired
  }*/

  /*componentWillReceiveProps(nextProps) {
      console.log('login componentWillReceiveProps' + JSON.stringify(nextProps));
  }*/

  componentDidMount() {
    document.title = "Log in"
  }

  render() {
    const { handleSubmit, loginError} = this.props
    //const { isLoggingIn } = this.props

    return (
      <>
      <TopNav/>
      <div className="container">
        <form onSubmit={handleSubmit}>
          <h1>Login</h1>
          {/*<span>Please start using your @st-helens.org.uk email address and password to log in (13th Nov 2018)</span>*/}
          <div className="form-group">
            <div className="row">
              <div className="col-sm-4">
          {
            (loginError) ? <div className="alert alert-danger" role="alert">{loginError}</div> : <span/>
          }
              <Field autocomplete="email" label="Email" className="form-control" name="email" component={renderField} type="text" validate={[required]}/>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-sm-4">
                <Field autocomplete="current-password" label="Password" className="form-control" name="password" component={renderField} type="password" validate={[required]}/>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-lg">Login</button>
        </form>
      </div>
      <Footer/>
      </>
    )
  }
}

LoginForm = reduxForm({
  form: 'login'
})(LoginForm)

// Decorate with connect to read form values
/*const selector = formValueSelector('login') // <-- same as form name
LoginForm = connect(state => {
  // can select values individually
  //const typeValue = selector(state, 'type')
  // or together as a group
  return {
      //typeValue,
  }
})(LoginForm)*/

const mapStateToProps = state => {
  //const { } = state
  return {
  }
}

export default withRouter(connect(mapStateToProps)(LoginForm))
