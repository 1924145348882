import React, { Component } from "react";
import { Alert, Carousel } from 'react-bootstrap';
import TopNav from './TopNav'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faEnvelope, faCheckSquare } from '@fortawesome/free-solid-svg-icons'

class Home extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      show: true
    };
  }

  componentDidMount() {
    document.title = "AskQuestions.live - the easiest way to host a Q&A session"
    //document.description = "Some description"
  }

  render() {
    const {user} = this.props;
    return (
      <>
      <TopNav user={user}/>
      <div className="container">
      <Alert bsStyle="info">AskQuestions is currently in beta and its use is by invitation only. &nbsp;
            <Link to="/contact" className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEnvelope} /> Request an invitation</Link>
          </Alert>
          <div className="row">
          <div className="col-sm-8">
        <h2>Receive every question.<br />Provide every answer.</h2>
        <h3>AskQuestions.live makes it easy to host a Q&amp;A session</h3>
        <blockquote>
        <ol>
          <li>We issue a standard-rate phone number for questions to be sent by SMS</li>
          <li>Invite the audience at your event to send their questions to this number</li>
          <li>Organise all the questions on your mobile device as they arrive</li>
        </ol>
        <ul>
          <li>Optionally allow assistants (on additional devices) to filter, re-order and group questions in realtime</li>
          <li>Optionally have the current question shown on a projector that can display a webpage</li>
        </ul>
        </blockquote>
        <div className="panel panel-default">
          <div className="panel-heading">
            <h3 className="panel-title">Benefits</h3>
          </div>
          <div className="panel-body">
            <ul className="list-unstyled">
              <li><FontAwesomeIcon icon={faCheckSquare} /> More questions can be gathered in a shorter space of time than a "raise your hand" Q&amp;A</li>
              <li><FontAwesomeIcon icon={faCheckSquare} /> Keep an archive of questions for analysis and insights</li>
              <li><FontAwesomeIcon icon={faCheckSquare} /> No need for questions to be asked out loud, nor a mic-runner</li>
              <li><FontAwesomeIcon icon={faCheckSquare} /> Questioners can remain anonymous as phone numbers are hidden</li>
              <li><FontAwesomeIcon icon={faCheckSquare} /> With assistants moderating questions, you can concentrate on giving the best response</li>
            </ul>
          </div>
        </div>
        <div style={{textAlign:'center'}}>
          <Link to="/help#demo" className="btn btn-lg btn-warning btn-block"><FontAwesomeIcon icon={faPlayCircle} /> Try the demo</Link>
        </div>
        {/*}<Link to="/contact" className="btn btn-lg btn-primary"><FontAwesomeIcon icon={faEnvelope} /> Request an invitation</Link>*/}
        </div>
        <div className="col-sm-4">
          <div style={{marginTop:'10px'}}>
          <Carousel controls={false} indicators={false} style={{maxWidth:'300px',margin:'auto'}}>
          <Carousel.Item style={{textAlign:'center'}}>
            <img width={300} alt="900x500" src="/img/phone.png" />
            <Carousel.Caption style={{bottom:'-20px'}}>
              <h3>Sent questions</h3>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img width={300} alt="900x500" src="/img/phone2.png" />
            <Carousel.Caption style={{bottom:'-20px'}}>
              <h3>Admin view</h3>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
          </div>
          </div>
        </div>
        {/*
          Pricing
          List of clients
          */}
      </div>
      <Footer/>
      </>
    );
  }
}

export default Home
